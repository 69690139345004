/*
  Usage: class="btn btn-[size] btn-[type]"
  Size: xs, s, sm, md, lg, xl
  Type: primary, secondary-gray, secondary-color, destructive-primary, destructive-secondary-gray ...
*/

.btn {
  @apply font-medium rounded-md inline-flex gap-2 justify-center items-center disabled:opacity-50 enabled:cursor-pointer;

  &-xs {
    @apply h-7 px-2;
  }

  &-sm {
    @apply h-8 px-3;
  }

  &-md {
    @apply h-9 px-4;
  }

  &-lg {
    @apply h-10 px-5;
  }

  &-xl {
    @apply h-11 px-6;
  }

  &-primary {
    @apply text-white bg-blue-600 enabled:hover:bg-blue-700 enabled:active:bg-blue-800;
  }

  &-secondary {
    @apply text-blue-600 border enabled:hover:bg-gray-100  enabled:active:bg-gray-100 enabled:active:border-blue-600 disabled:text-gray-500;
  }

  &-danger {
    @apply text-white bg-error-600 border-error-600 enabled:hover:bg-error-700 enabled:active:bg-error-800;
  }

  &-outline-primary {
    @apply text-blue-600 border border-blue-600 enabled:hover:bg-blue-100 disabled:bg-gray-100 disabled:text-gray-900 disabled:border-transparent;
  }

  &-outline-secondary {
    @apply text-gray-900 border border-gray-300 bg-white enabled:hover:bg-gray-100 enabled:active:bg-gray-200 disabled:bg-gray-100 disabled:border-transparent;
  }

  &-outline-danger {
    @apply text-error-600 border border-error-600 enabled:hover:bg-error-100 disabled:bg-gray-100 disabled:text-gray-900 disabled:border-transparent;
  }

  &-ghost-primary {
    @apply text-primary-600  enabled:hover:bg-primary-600/10  disabled:text-gray-900;
  }

  &-ghost-secondary {
    @apply text-gray-900  enabled:hover:bg-gray-100 enabled:active:bg-gray-200;
  }
}
