/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* SCSS partials */
@import 'theme/scss/core-global.scss';

html {
  color: #101828;
}
